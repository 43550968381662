<template>
  <div class="">
    <b-button v-b-toggle.treatmentsReservation class="btn btn-lg btn-collapse">
      {{ $t("treatmentsReservation") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="treatmentsReservation">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="servicesReservationTreatmentPlanView"
              v-model="
                usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanView
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="servicesReservationTreatmentPlanView"
              >{{ $t("view") }}</label
            >
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="servicesReservationTreatmentPlanAdd"
              v-model="
                usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanAdd
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="servicesReservationTreatmentPlanAdd"
              >{{ $t("add") }}</label
            >
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="servicesReservationTreatmentPlanUpdate"
              v-model="
                usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanUpdate
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="servicesReservationTreatmentPlanUpdate"
              >{{ $t("edit") }}</label
            >
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="servicesReservationTreatmentPlanArchive"
              v-model="
                usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanArchive
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="servicesReservationTreatmentPlanArchive"
              >{{ $t("archive") }}</label
            >
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="servicesReservationTreatmentPlanDelete"
              v-model="
                usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanDelete
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="servicesReservationTreatmentPlanDelete"
              >{{ $t("finalDelete") }}</label
            >
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="servicesReservationTreatmentPlanRestore"
              v-model="
                usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanRestore
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="servicesReservationTreatmentPlanRestore"
              >{{ $t("restore") }}</label
            >
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegeServicesReservationTreatmentPlan",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeServicesReservationTreatmentPlan"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
