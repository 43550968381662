import { render, staticRenderFns } from "./CancelServicesReservation.vue?vue&type=template&id=04fc9524&scoped=true&"
import script from "./CancelServicesReservation.vue?vue&type=script&lang=js&"
export * from "./CancelServicesReservation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04fc9524",
  null
  
)

export default component.exports