var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.treatmentsReservation",modifiers:{"treatmentsReservation":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("treatmentsReservation"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"treatmentsReservation"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanView
            ),expression:"\n              usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationTreatmentPlanView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanView
            )?_vm._i(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanView
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationTreatmentPlanView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanAdd
            ),expression:"\n              usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationTreatmentPlanAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanAdd
            )?_vm._i(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanAdd
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationTreatmentPlanAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanUpdate
            ),expression:"\n              usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationTreatmentPlanUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanUpdate
            )?_vm._i(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanUpdate
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationTreatmentPlanUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanArchive
            ),expression:"\n              usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanArchive\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationTreatmentPlanArchive"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanArchive
            )?_vm._i(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanArchive
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanArchive
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanArchive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanArchive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanArchive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanArchive", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationTreatmentPlanArchive"}},[_vm._v(_vm._s(_vm.$t("archive")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanDelete
            ),expression:"\n              usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationTreatmentPlanDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanDelete
            )?_vm._i(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanDelete
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationTreatmentPlanDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanRestore
            ),expression:"\n              usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanRestore\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationTreatmentPlanRestore"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanRestore
            )?_vm._i(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanRestore
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanRestore
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservationTreatmentPlan.servicesReservationTreatmentPlanRestore
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanRestore", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanRestore", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservationTreatmentPlan, "servicesReservationTreatmentPlanRestore", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationTreatmentPlanRestore"}},[_vm._v(_vm._s(_vm.$t("restore")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }