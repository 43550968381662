export default class UsersPrivilegeServicesReservationTreatmentPlan {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.servicesReservationTreatmentPlanView = false;
    this.servicesReservationTreatmentPlanAdd = false;
    this.servicesReservationTreatmentPlanUpdate = false;
    this.servicesReservationTreatmentPlanArchive = false;
    this.servicesReservationTreatmentPlanRestore = false;
    this.servicesReservationTreatmentPlanDelete = false;
  }
  fillData(data) {
    this.servicesReservationTreatmentPlanView =
      data.servicesReservationTreatmentPlanView;
    this.servicesReservationTreatmentPlanAdd =
      data.servicesReservationTreatmentPlanAdd;
    this.servicesReservationTreatmentPlanUpdate =
      data.servicesReservationTreatmentPlanUpdate;
    this.servicesReservationTreatmentPlanArchive =
      data.servicesReservationTreatmentPlanArchive;
    this.servicesReservationTreatmentPlanRestore =
      data.servicesReservationTreatmentPlanRestore;
    this.servicesReservationTreatmentPlanDelete =
      data.servicesReservationTreatmentPlanDelete;
  }
}
