import { render, staticRenderFns } from "./ExpenseAdd.vue?vue&type=template&id=4c3a0d80&scoped=true&"
import script from "./ExpenseAdd.vue?vue&type=script&lang=js&"
export * from "./ExpenseAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3a0d80",
  null
  
)

export default component.exports