<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="servicesReservationsData">
      <ServicesReservationsTable
        v-on:setServicesReservationData="setServicesReservationData($event)"
        :servicesReservationsData="servicesReservationsData"
        :servicesReservationData="servicesReservationData"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="filterServicesReservationData.paginationData"
        v-on:changePage="changePage"
      />
      <ServicesReservationsNewPayment
        :servicesReservationPayData="servicesReservationPayData"
        v-on:refresh="refresh"
      />
      <CancelServicesReservation
        :servicesReservationCancelData="servicesReservationCancelData"
        v-on:refresh="refresh"
      />
      <ApproveCancelServicesReservation
        :servicesReservationCancelData="servicesReservationCancelData"
        v-on:refresh="refresh"
      />
      <ApproveOnServicesReservation
        :servicesReservationData="servicesReservationData"
        v-on:refresh="refresh"
      />
      <AttendServicesReservation
        :servicesReservationData="servicesReservationData"
        v-on:refresh="refresh"
      />
      <EnterServicesReservation
        :servicesReservationData="servicesReservationData"
        v-on:refresh="refresh"
      />
      <WaitForComeBackServicesReservation
        :servicesReservationData="servicesReservationData"
        v-on:refresh="refresh"
      />
      <FinishServicesReservation
        :servicesReservationData="servicesReservationData"
        v-on:refresh="refresh"
      />
    </div>

    <ServicesReservationFloatBtns
      :filterServicesReservationData="filterServicesReservationData"
      v-on:search="refresh"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import { EMPLOYEE_TYPE } from "@/utils/constantLists";
import ServicesReservationsTable from "@/components/servicesReservations/ServicesReservationsTable.vue";
import ServicesReservationsNewPayment from "@/components/servicesReservations/ServicesReservationsNewPayment.vue";
import CancelServicesReservation from "@/components/servicesReservations/CancelServicesReservation.vue";
import ApproveCancelServicesReservation from "@/components/servicesReservations/ApproveCancelServicesReservation.vue";
import ApproveOnServicesReservation from "@/components/servicesReservations/ApproveOnServicesReservation.vue";
import AttendServicesReservation from "@/components/servicesReservations/AttendServicesReservation.vue";
import EnterServicesReservation from "@/components/servicesReservations/EnterServicesReservation.vue";
import WaitForComeBackServicesReservation from "@/components/servicesReservations/WaitForComeBackServicesReservation.vue";
import FinishServicesReservation from "@/components/servicesReservations/FinishServicesReservation.vue";
import ServicesReservationFloatBtns from "@/components/servicesReservations/ServicesReservationFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import ServicesReservation from "@/models/servicesReservations/ServicesReservation";
import ServicesReservationCancel from "@/models/servicesReservations/ServicesReservationCancel";
import ServicesReservationPay from "@/models/servicesPayments/ServicesReservationPay";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "ServicesReservations",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ServicesReservationsTable,
    ServicesReservationsNewPayment,
    CancelServicesReservation,
    ApproveCancelServicesReservation,
    ApproveOnServicesReservation,
    AttendServicesReservation,
    EnterServicesReservation,
    WaitForComeBackServicesReservation,
    FinishServicesReservation,
    ServicesReservationFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      employeeTypeToken:
        this.$store.getters.userData.employeePersonalData.employeeTypeToken ||
        null,
      language: localStorage.getItem("userLanguage") || "ar",
      textSearch: "",
      paginationData: {
        totalPages: 1,
        totalItems: 0,
        countItemsInPage: 0,
        selfPage: 1,
      },
      servicesReservationsData: null,
      servicesReservationData: new ServicesReservation(),
      servicesReservationPayData: new ServicesReservationPay(),
      filterServicesReservationData: new ServicesReservation(),
      servicesReservationCancelData: new ServicesReservationCancel(),
    };
  },
  methods: {
    changePage(page) {
      this.filterServicesReservationData.paginationData.selfPage = page;
      this.getAllServicesReservations();
    },
    refresh() {
      this.servicesReservationsData = null;
      this.getAllServicesReservations();
    },
    setServicesReservationData(data) {
      this.servicesReservationData.note = "";
      this.servicesReservationData.fillData(data);
      this.servicesReservationPayData.setInitialValue();
      this.servicesReservationPayData.reservationToken = data.reservationToken;
      this.servicesReservationPayData.methodPaidValue = data.remainingAmount;
      this.servicesReservationCancelData.reservationToken =
        data.reservationToken;
      this.servicesReservationCancelData.totalPaid = data.totalPaid;
      this.servicesReservationCancelData.refundPercentageFromPaid = "";
      this.servicesReservationCancelData.refoundValue = "";
    },
    async getAllServicesReservations() {
      this.isLoading = true;
      try {
        let response =
          await this.filterServicesReservationData.getAllServicesReservations(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.servicesReservationsData =
            response.data.servicesReservationPagination.servicesReservationData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.servicesReservationsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.servicesReservationsData = null;
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        } else {
          this.servicesReservationsData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.servicesReservationsData = null;
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    if (this.employeeTypeToken == EMPLOYEE_TYPE.Doctor)
      this.filterServicesReservationData.employeeToken =
        this.$store.getters.userData.employeePersonalData.employeeToken;
    this.getAllServicesReservations();
  },
};
</script>
