export default {
  state: {
    userData: null,
  },
  getters: {
    userData: (state) => state.userData,
  },
  mutations: {
    UPDATE_USER_DATA: (state, userData) => {
      state.userData = userData;
    },
  },
  actions: {
    loginUser(context, userData) {
      context.commit("UPDATE_USER_DATA", userData);
    },
    updateUserData(context, userData) {
      context.commit("UPDATE_USER_DATA", userData);
    },
  },
  modules: {},
};
